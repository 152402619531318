import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import {
  DiHtml5,
  DiCss3,
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiMysql,
  DiJava,
  DiGit,
  DiBootstrap,
  DiWordpress,
} from "react-icons/di";

function Techstack() {
  return (
    <Container fluid id="skill">
      <Row
        style={{
          justifyContent: "center",
          paddingTop: "55px",
          paddingBottom: "50px",
          color: "white",
        }}
      >
        <h1 className="project-heading" style={{ paddingBottom: "50px" }}>
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Col xs={4} md={2} className="tech-icons">
          <DiHtml5 />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiCss3 />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiJavascript1 />
        </Col>

        <Col xs={4} md={2} className="tech-icons">
          <DiNodejs />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiReact />
        </Col>

        <Col xs={4} md={2} className="tech-icons">
          <DiMongodb />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiMysql />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiPython />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiJava />
        </Col>

        <Col xs={4} md={2} className="tech-icons">
          <DiGit />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiBootstrap />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiWordpress />
        </Col>
      </Row>
    </Container>
  );
}

export default Techstack;
