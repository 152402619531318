import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
        <ScrollToTop smooth style={{width: "55px", height: "55px", borderRadius: "50%"}}/>
          <Col md={12} className="home-about-description ">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              "I'm Sunny Kumar, a programmer who loves{" "}
              <i>
                <b className="purple">
                  learning and exploring new technologies
                </b>
              </i>
              .<br />
              <br />
              I'm passionate about{" "}
              <i>
                <b className="purple"> solving problems</b>
              </i>{" "}
              and
              <i>
                <b className="purple"> developing web applications</b>
              </i>{" "}
              .<br /> <br />I am fluent in classics like
              <i>
                <b className="purple"> Java, MERN stack. </b>
              </i>
              <br /> <br />
              I'm{" "}
              <i>
                <b className="purple"> open to job opportunities</b>
              </i>{" "}
              that match my skills and interests."
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
