import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Wanderlust from "../../Assets/Projects/wanderlust.png";
import ZerodhaClone from "../../Assets/Projects/zerodha-clone.png";

function Projects() {
  return (
    <Container fluid className="project-section" id="project">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Wanderlust}
              isBlog={false}
              title="Wanderlust"
              description="Wanderlust web app, similar to Airbnb, allows users to book unique stays , it
offers secure authentication, advanced search, and a booking system.
HTML, CSS, JavaScript, Node.js, Express, Bootstrap, Tailwind CSS, and MongoDB"
              ghLink="https://github.com/sunnysing1202/wanderlust-project"
              demoLink="https://wanderlust-project-dbgu.onrender.com/listings"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ZerodhaClone}
              isBlog={false}
              title="Zerodha Clone"
              description="A Zerodha clone built with React, Bootstrap, and Material-UI. It features a responsive dashboard for tracking investments, viewing market trends, and managing portfolios with a sleek, modern design."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://zerodha-clone-new.web.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
